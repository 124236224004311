import { SET_IS_CLIENT } from "utils/action-types";

const initialState = {
	isClient: false,
};

const globalReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_IS_CLIENT:
			return {
				...state,
				isClient: true
			};

		default:
			return state;
	}
};

export default globalReducer;
