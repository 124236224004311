import {
	LOGIN, LOGOUT, SIGNUP, GET_CUSTOMER, FETCH_LOYALTY_LION,
	UPDATE_CUSTOMER_ADDRESS, GET_CUSTOMER_ADDRESSES, DELETE_CUSTOMER_ADDRESS,
	CREATE_CUSTOMER_ADDRESS, CHANGE_DEFAULT_ADDRESS, LOAD_MORE_ADDRESSES,
	GET_CUSTOMER_ORDERS, LOAD_MORE_ORDERS, CLEAR_AUTH_ERROR, UPDATE_CUSTOMER_STATE
} from "utils/action-types";
import { CUSTOMER_STATE } from "utils/constants";

const initialState = {
	displayName: "",
	firstName: "",
	lastName: "",
	emailAddress: {},
	creationDate: "",
	addresses: {},
	addressPageInfo: {},
	defaultAddress: null,
	id: "",
	loyaltyLion: null,
	customerState: null,
	authenticated: false,
	hasOrders: false,
	orders: {
		data: [],
		pageInfo: {
			admin: null,
			storefront: null
		}
	},
	error: null,
	disabled: false,
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_AUTH_ERROR: {
			return {
				...state,
				error: ""
			};
		}
		case LOGIN:
		case SIGNUP:
			return {
				...state,
				...action.payload,
			};
		case UPDATE_CUSTOMER_STATE:
			return {
				...state,
				customerState: action.payload,
			};
		case GET_CUSTOMER:
			return {
				...state,
				...action.user,
				customerState: action.customerState,
				authenticated: action.customerState === CUSTOMER_STATE.Logged_in,
			};
		case FETCH_LOYALTY_LION:
			return {
				...state,
				loyaltyLion: action.payload
			};
		case LOGOUT:
			return initialState;

		case GET_CUSTOMER_ADDRESSES:
			return {
				...state,
				addresses: {
					...state.addresses,
					...action.addresses
				},
				addressPageInfo: action.pageInfo,
				defaultAddress: action.defaultAddress
			};
		case LOAD_MORE_ADDRESSES:
			return {
				...state,
				addresses: {
					...state.addresses,
					...action.addresses
				},
				addressPageInfo: action.pageInfo,
			};

		case CREATE_CUSTOMER_ADDRESS: {
			return {
				...state,
				addresses: {
					...state.addresses,
					[action.payload.id]: action.payload.address,
				},
				defaultAddress: Object.keys(state.addresses).length === 0 ? action.payload.address : state.defaultAddress
			};
		}

		case UPDATE_CUSTOMER_ADDRESS:
			return {
				...state,
				addresses: {
					...state.addresses,
					[action.cleanId]: action.payload
				}
			};

		case DELETE_CUSTOMER_ADDRESS: {
			const new_addresses = { ...state.addresses };
			delete new_addresses[action.cleanId];
			const defaultAddress = Object.keys(new_addresses).length > 0 ? state.defaultAddress : null;
			return {
				...state,
				addresses: new_addresses,
				defaultAddress
			};
		}

		case GET_CUSTOMER_ORDERS: {
			const latestSubOrder = action.payload.orders.find(o => o.isSubscription);

			return {
				...state,
				orders: {
					data: action.payload.orders,
					pageInfo: action.payload.pageInfo
				},

				latestSubOrder: {
					fulfillments: latestSubOrder?.fulfillments,
					createdAt: latestSubOrder?.createdAt,
					deliveryDate: latestSubOrder?.customAttributes?.find(attribute => attribute.key === "Lieferdatum")?.value,
					totalQuantity: latestSubOrder?.lineItems?.nodes?.reduce((acc, item) => acc + item.quantity, 0),
				}
			};
		}

		case LOAD_MORE_ORDERS:
			return {
				...state,
				orders: {
					data: [...state.orders.data, ...action.payload.orders],
					pageInfo: action.payload.pageInfo
				}
			};

		case CHANGE_DEFAULT_ADDRESS:
			return {
				...state,
				defaultAddress: action.payload
			};

		default:
			return state;
	}

};


export default authReducer;
