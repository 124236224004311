import {
	FETCH_CART, CART_ERROR, UPDATE_ITEM, ADD_TO_CART, REMOVE_FROM_CART, OPEN_CART, CLOSE_CART, ADD_CART_ITEMS,
	TOGGLE_CHECKOUT_LOADING, SELECT_BOX, SELECT_MIN_BOX, SET_DELIVERY_FREQUENCY, RESET_REMOVE_ITEM_CLICK
} from "utils/action-types";

const initialState = {
	items: {},
	delivery: null,
	createdAt: null,
	checkoutUrl: "",
	totalPrice: 0,
	totalQuantity: 0,
	totalProgress: 0,
	discountCode: "",
	isOpen: false,
	loading: true,
	updating: false,
	error: false,
	allGiftCards: false,
	removeItemClick: 0,
	checkoutLoading: false,
	activeBox: null,
	minBox: null,
	bundlesAdded: false
};

const cartReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_CART:
			return {
				...state,
				isOpen: true
			};
		case CLOSE_CART:
			return {
				...state,
				isOpen: false
			};

		case SET_DELIVERY_FREQUENCY:
			return {
				...state,
				delivery: action.payload
			};
		case TOGGLE_CHECKOUT_LOADING:
			return {
				...state,
				checkoutLoading: !state.checkoutLoading
			};
		case FETCH_CART:
			return {
				...state,
				...action.payload,
				loading: false,
				updating: false,
			};
		case CART_ERROR: {
			return {
				...state,
				error: true
			};
		}
		case ADD_TO_CART: {
			const totalProgress = state.totalProgress + action.payload.weight;

			return {
				...state,
				items: {
					...state.items,
					[action.payload.id]: {
						...state.items[action.payload.id],
						totalPrice: action.totalPrice,
						title: action.title,
						image: action.image,
						quantity: 1,
					}
				},
				totalQuantity: state.totalQuantity + 1,
				totalProgress: action.payload.weight > 0 ? totalProgress : state.totalProgress,
				removeItemClick: 0,
				isOpen: action?.payload?.shouldNotOpenCart ? false : totalProgress === state.activeBox.size.value,
			};
		}

		case REMOVE_FROM_CART:
			return {
				...state,
				items: {
					...state.items,
					[action.id]: null
				},
				totalQuantity: state.totalQuantity - 1,
				totalProgress: state.totalProgress - action.weight,
				removeItemClick: ++state.removeItemClick,
				updating: true
			};
		case RESET_REMOVE_ITEM_CLICK: {
			return { ...state, removeItemClick: 0 };
		}
		case UPDATE_ITEM: {
			let isCartOpen = state.isOpen;
			if (!isCartOpen)
				isCartOpen = action.shouldNotOpenCart ? false : state.totalProgress === state.activeBox.size.value;

			return {
				...state,
				items: {
					...state.items,
					[action.id]: {
						...state.items[action.id],
						quantity: action.quantity,
					},
				},
				totalQuantity: action.totalQuantity,
				totalProgress: action.totalProgress,
				removeItemClick: state.items[action.id].quantity > action.quantity ? ++state.removeItemClick : 0,
				updating: true,
				isOpen: isCartOpen,
			};
		}
		case SELECT_BOX: {
			return {
				...state,
				activeBox: action.box,
				bundlesAdded: false,
			};
		}

		case SELECT_MIN_BOX: {
			return {
				...state,
				minBox: action.box,
				bundlesAdded: false,
			};
		}

		case ADD_CART_ITEMS: {
			return {
				...state,
				bundlesAdded: true,
			};
		}

		default:
			return state;
	}
};

export default cartReducer;
